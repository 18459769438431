import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, CardMedia, CircularProgress } from '@mui/material';
import supabase from './supabaseClient';
import './TopArtists.css';

function TopArtists() {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopArtists = async () => {
      try {
        // Get session from Supabase
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError || !session) {
          console.error('Session Error:', sessionError);
          setError('Failed to get session.');
          return;
        }

        const accessToken = session.provider_token;
        console.log('Access Token:', accessToken); // Debug log

        // Fetch top artists from Spotify
        const response = await fetch('https://api.spotify.com/v1/me/top/artists?time_range=long_term', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text(); // Log the error response text
          console.error('Spotify API Error:', errorMessage);
          throw new Error('Failed to fetch top artists from Spotify');
        }

        const data = await response.json();
        const artistsData = data.items;
        console.log('Artists Data:', artistsData); // Debug log

        // Fetch Ticketmaster IDs for each artist
        const artistsWithTicketmasterId = await Promise.all(
          artistsData.map(async (artist) => {
            const ticketmasterId = await fetchTicketmasterId(artist.name);
            return {
              ...artist,
              ticketmasterId,
            };
          })
        );

        setArtists(artistsWithTicketmasterId);
      } catch (error) {
        console.error('Error fetching top artists:', error);
        setError('Failed to load top artists');
      } finally {
        setLoading(false);
      }
    };

    fetchTopArtists();
  }, []);

  const fetchTicketmasterId = async (artistName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-ticketmaster-id?artistName=${encodeURIComponent(artistName)}`);
      
      console.log(`Fetching Ticketmaster ID for: ${artistName}`); // Debug log

      if (!response.ok) {
        const errorMessage = await response.text(); // Log the error response text
        console.error('Ticketmaster API Error:', errorMessage);
        throw new Error('Failed to fetch Ticketmaster ID');
      }
      
      const data = await response.json();
      console.log(`Ticketmaster ID for ${artistName}:`, data); // Debug log
      return data.ticketmasterId; // Adjust based on your server response
    } catch (error) {
      console.error('Error fetching Ticketmaster ID:', error);
      return null;
    }
  };

  const handleArtistClick = (artistId, ticketmasterId) => {
    navigate(`/artist/${artistId}/${ticketmasterId}`);
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center' }}>
        Your Top Artists
      </Typography>
      <Grid container spacing={4}>
        {artists.map((artist) => (
          <Grid item xs={12} sm={6} md={4} key={artist.id}>
            <Card 
              onClick={() => handleArtistClick(artist.id, artist.ticketmasterId)} 
              sx={{ 
                cursor: 'pointer', 
                boxShadow: 3, 
                transition: '0.3s', 
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={artist.images[0]?.url}
                alt={artist.name}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {artist.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default TopArtists;
