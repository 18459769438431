import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Alert,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import './TicketPurchase.css';

const mockSections = [
  { section: 'Section A', available: 100 },
  { section: 'Section B', available: 50 },
  { section: 'Section C', available: 20 },
];

function TicketPurchase() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedTickets, setSelectedTickets] = useState(2);
  const [loading, setLoading] = useState(true);
  const [purchaseError, setPurchaseError] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [newCard, setNewCard] = useState({ number: '', exp: '', cvc: '' });
  const [showAddCard, setShowAddCard] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const TICKETMASTER_API_KEY = "WcfYRQg06dOwSs7PInzMqIN6NzWu2Y5A";

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`https://app.ticketmaster.com/discovery/v2/events/${eventId}.json?apikey=${TICKETMASTER_API_KEY}`);
        setEvent({
          name: response.data.name,
          date: response.data.dates.start.localDate,
        });
      } catch (error) {
        console.error('Error fetching event details:', error);
        setPurchaseError('Failed to fetch event details');
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await axios.get('http://localhost:8888/payment-methods', {
          params: { customerId: 'your-customer-id' },
        });
        setPaymentMethods(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedPaymentMethod(response.data.data[0].id); // Set the first payment method as default
        }
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleTicketCountChange = (event) => {
    setSelectedTickets(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleNewCardChange = (event) => {
    setNewCard({ ...newCard, [event.target.name]: event.target.value });
  };

  const handlePurchase = async () => {
    if (selectedTickets !== 2 && selectedTickets !== 4) {
      setPurchaseError('You can only purchase 2 or 4 tickets.');
      return;
    }

    if (!selectedSection) {
      setPurchaseError('Please select a section.');
      return;
    }

    if (!selectedPaymentMethod) {
      setPurchaseError('Please select a payment method.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:8888/create-payment-intent-with-method', {
        amount: calculateAmount(selectedTickets),
        customerId: 'your-customer-id',
        paymentMethodId: selectedPaymentMethod,
      });

      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error('Error during payment creation:', error);
      setPurchaseError('Failed to create payment intent.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: selectedPaymentMethod,
    });

    if (error) {
      console.error('Payment error:', error);
      setPurchaseError('Payment failed.');
      setSnackbarOpen(true);
    } else {
      if (paymentIntent.status === 'succeeded') {
        await generateGoogleWalletPass(selectedTickets);
      }
    }
  };

  const calculateAmount = (ticketCount) => {
    const pricePerTicket = 50; // in dollars
    return ticketCount * pricePerTicket * 100; // amount in cents
  };

  const generateGoogleWalletPass = async (ticketCount) => {
    try {
      await axios.post('http://localhost:8888/generate-google-wallet-pass', {
        userEmail: 'user@example.com',
        eventName: event.name,
        eventDate: event.date,
        ticketCount,
      });
      alert('Tickets sent to your Google Wallet!');
    } catch (error) {
      console.error('Error generating Google Wallet pass:', error);
      setPurchaseError('Failed to send tickets to Google Wallet.');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="md">
      <div className="ticket-purchase-container fade-in">
        <Typography variant="h4" gutterBottom className="bold">Your Ticket Purchase</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Card variant="outlined" sx={{ mb: 4 }}>
            <CardContent>
              {event && (
                <>
                  <Typography variant="h6" gutterBottom className="checkout-header bold">
                    {event.name}
                  </Typography>
                  <Typography variant="body1" gutterBottom className="bold">
                    Date: {new Date(event.date).toLocaleDateString()}
                  </Typography>
                  <Divider sx={{ mb: 3 }} />
                </>
              )}

              <Typography variant="h6" gutterBottom className="bold">Select a Section</Typography>
              <FormControl fullWidth className="section-select" sx={{ mb: 3 }}>
                <InputLabel>Section</InputLabel>
                <Select
                  value={selectedSection}
                  onChange={handleSectionChange}
                  renderValue={(selected) => selected || "Select a section"}
                >
                  {mockSections.map((section) => (
                    <MenuItem key={section.section} value={section.section}>
                      {section.section} (Available: {section.available})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="h6" gutterBottom className="bold">Number of Tickets</Typography>
              <FormControl fullWidth className="ticket-count-select" sx={{ mb: 3 }}>
                <Select
                  value={selectedTickets}
                  onChange={handleTicketCountChange}
                  renderValue={(selected) => `${selected} Tickets`}
                >
                  <MenuItem value={2}>2 Tickets</MenuItem>
                  <MenuItem value={4}>4 Tickets</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="h6" gutterBottom className="bold">Select a Payment Method</Typography>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Payment Method</InputLabel>
                <Select
                  value={selectedPaymentMethod}
                  onChange={handlePaymentMethodChange}
                  renderValue={(selected) => {
                    const selectedMethod = paymentMethods.find(method => method.id === selected);
                    return selectedMethod ? `**** **** **** ${selectedMethod.card.last4}` : 'Select a payment method';
                  }}
                >
                  {paymentMethods.map((method) => (
                    <MenuItem key={method.id} value={method.id}>
                      {`**** **** **** ${method.card.last4}`}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowAddCard((prev) => !prev)}
                  sx={{ mt: 2 }}
                >
                  {showAddCard ? 'Cancel' : 'Add New Payment Method'}
                </Button>
                {showAddCard && (
                  <div className="new-card-form">
                    <Typography variant="h6" className="payment-method-header">Add New Card</Typography>
                    <TextField
                      label="Card Number"
                      name="number"
                      variant="outlined"
                      fullWidth
                      value={newCard.number}
                      onChange={handleNewCardChange}
                      sx={{ mb: 2 }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Expiration Date (MM/YY)"
                          name="exp"
                          variant="outlined"
                          fullWidth
                          value={newCard.exp}
                          onChange={handleNewCardChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="CVC"
                          name="cvc"
                          variant="outlined"
                          fullWidth
                          value={newCard.cvc}
                          onChange={handleNewCardChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePurchase}
                      sx={{ mt: 2 }}
                    >
                      Save Card
                    </Button>
                  </div>
                )}
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Complete Purchase
              </Button>
              {purchaseError && (
                <Alert severity="error" onClose={() => setPurchaseError(null)}>
                  {purchaseError}
                </Alert>
              )}
            </CardContent>
          </Card>
        )}

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
            {purchaseError || 'An error occurred'}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
}

export default TicketPurchase;
