import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import './Header.css'; 
import companyLogo from './assets/company-logo.png';

function Header({ onSignOut }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onSignOut();
    handleMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ backgroundColor: '#121212' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img src={companyLogo} alt="Company Logo" style={{ width: 40, height: 40 }} />
          <Typography variant="h6" sx={{ ml: 2 }}>
            FanFidel
          </Typography>
        </Box>
        <Button color="inherit" component={Link} to="/" sx={{ mx: 1 }}>
          Home
        </Button>
        <Button color="inherit" component={Link} to="/about" sx={{ mx: 1 }}>
          About
        </Button>
        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{ sx: { width: 200, backgroundColor: '#inherit' } }}
        >
          <MenuItem component={Link} to="/account-profile" onClick={handleMenuClose}>
            My Account
          </MenuItem>
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
