import React from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();

  const handleMeetTheTeamClick = () => {
    navigate('/meet-the-team'); // Navigate to the Meet the Team page
  };

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        About Fan Fidel
      </Typography>
      <Typography variant="body1" paragraph>
        Fan Fidel is your go-to platform for discovering and purchasing tickets to the hottest events in town.
        Our mission is to provide fans with an easy and seamless ticket-buying experience, ensuring you never
        miss out on the events you love. From concerts to sports, theater to festivals, Fan Fidel connects you
        with the best events and helps you get your tickets with just a few clicks.
      </Typography>
      <Typography variant="h4" gutterBottom>
        Meet the Team
      </Typography>
      <Typography variant="body1" paragraph>
        Our team is made up of passionate individuals who are dedicated to bringing you the best in event
        experiences. Learn more about who we are and what drives us.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleMeetTheTeamClick}>
        Meet the Team
      </Button>
    </Container>
  );
};

export default About;
