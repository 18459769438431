import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Avatar,
  CircularProgress,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import supabase from './supabaseClient';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';

function AccountProfile() {
  const [profile, setProfile] = useState({
    displayName: '',
    email: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    profileImage: '',
  });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [error, setError] = useState(null);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]); // State for saved payment methods
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [addingNewCard, setAddingNewCard] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          const { data: profileData } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', session.user.id)
            .single();

          if (profileData) {
            setProfile({
              displayName: profileData.display_name || '',
              email: profileData.email || '',
              street: profileData.street || '',
              city: profileData.city || '',
              state: profileData.state || '',
              zipCode: profileData.zip_code || '',
              profileImage: profileData.profile_image || '',
            });
            await fetchOrderHistory(session.user.id);
            const paymentMethods = await fetchSavedPaymentMethods(session.user.id);
            setSavedPaymentMethods(paymentMethods);
          }
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to fetch profile information');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const fetchOrderHistory = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('orders')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false })
        .limit(10);

      if (error) throw error;

      setOrderHistory(data);
    } catch (error) {
      console.error('Error fetching order history:', error);
      setError('Failed to fetch order history.');
    }
  };

  const fetchSavedPaymentMethods = async (userId) => {
    try {
      const { data } = await axios.get(`http://localhost:8888/get-saved-payment-methods`, {
        params: { userId },
      });
      return data; // Assuming it returns an array of payment methods
    } catch (error) {
      console.error('Error fetching saved payment methods:', error);
      return [];
    }
  };

  const handleInputChange = (field, value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [field]: value,
    }));
  };

  const handleUpdateProfile = async () => {
    setUpdating(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const { displayName, email, street, city, state, zipCode } = profile;

        const { error } = await supabase.from('profiles').upsert({
          id: session.user.id,
          display_name: displayName,
          email: email,
          street: street,
          city: city,
          state: state,
          zip_code: zipCode,
        });

        if (error) {
          console.error('Error updating profile:', error);
          setError('Failed to update profile');
        } else {
          alert('Profile updated successfully!');
        }
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    } finally {
      setUpdating(false);
    }
  };

  const handleSavePaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('Error creating payment method:', error);
      setError('Failed to save payment method.');
    } else {
      try {
        await axios.post('http://localhost:8888/save-payment-method', {
          paymentMethodId: paymentMethod.id,
          userId: supabase.auth.user().id,
        });

        alert('Payment method saved successfully!');
        // Re-fetch saved payment methods after adding a new one
        const updatedPaymentMethods = await fetchSavedPaymentMethods(supabase.auth.user().id);
        setSavedPaymentMethods(updatedPaymentMethods);
        setAddingNewCard(false);
      } catch (saveError) {
        console.error('Error saving payment method:', saveError);
        setError('Failed to save payment method.');
      }
    }
  };

  const renderOrderHistory = () => {
    const ordersToShow = showMore ? orderHistory : orderHistory.slice(0, 5);

    return (
      <>
        {ordersToShow.map((order, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${order.artist} - ${order.venue}`}
              secondary={`Date: ${new Date(order.date).toLocaleDateString()} | Price: $${order.price} | Quantity: ${order.quantity}`}
            />
          </ListItem>
        ))}
        {orderHistory.length > 5 && (
          <Button onClick={() => setShowMore((prev) => !prev)} sx={{ mt: 2 }}>
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </>
    );
  };

  const handleSupportForm = () => {
    window.location.href = 'mailto:support@fanfidel.com?subject=Support Request';
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Your Account</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {profile.profileImage && (
            <Avatar src={profile.profileImage} alt="Profile" sx={{ width: 100, height: 100, mb: 2 }} />
          )}
          {/* Account Accordion */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Account</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  fullWidth
                  label="Display Name"
                  value={profile.displayName}
                  onChange={(e) => handleInputChange('displayName', e.target.value)}
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  value={profile.email}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                  sx={{ mb: 3 }}
                />
                {/* Address Fields */}
                <TextField
                  fullWidth
                  label="Street"
                  value={profile.street}
                  onChange={(e) => handleInputChange('street', e.target.value)}
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  label="City"
                  value={profile.city}
                  onChange={(e) => handleInputChange('city', e.target.value)}
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  label="State"
                  value={profile.state}
                  onChange={(e) => handleInputChange('state', e.target.value)}
                  sx={{ mb: 3 }}
                />
                <TextField
                  fullWidth
                  label="Zip Code"
                  value={profile.zipCode}
                  onChange={(e) => handleInputChange('zipCode', e.target.value)}
                  sx={{ mb: 3 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateProfile}
                  disabled={updating}
                >
                  {updating ? 'Updating...' : 'Update Profile'}
                </Button>
                {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Payments Accordion */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Payments</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box component="form" noValidate autoComplete="off">
                <Typography variant="body1" sx={{ mb: 2 }}>Saved Payment Methods:</Typography>
                <RadioGroup value={selectedPaymentMethod} onChange={(e) => setSelectedPaymentMethod(e.target.value)}>
                  {savedPaymentMethods.map((method) => (
                    <FormControlLabel
                      key={method.id}
                      control={<Radio />}
                      label={`**** **** **** ${method.last4}`} // Display last 4 digits
                      value={method.id} // Use payment method ID
                    />
                  ))}
                </RadioGroup>
                {addingNewCard ? (
                  <>
                    <CardElement options={{ hidePostalCode: true }} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSavePaymentMethod}
                      sx={{ mt: 3 }}
                    >
                      Save Payment Method
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setAddingNewCard(true)}
                    sx={{ mt: 3 }}
                  >
                    Add New Card
                  </Button>
                )}
                {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Order History Accordion */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Order History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {renderOrderHistory()}
              </List>
            </AccordionDetails>
          </Accordion>

          {/* Fan Fidel Support Link */}
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleSupportForm}
          >
            Fan Fidel Support
          </Button>
        </>
      )}
    </Container>
  );
}

export default AccountProfile;
