import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7387ff', // Blue accent color
    },
    secondary: {
      main: '#4766c6', // Darker color for secondary elements
    },
    background: {
      // default: '#d0d7fc', // Light gray background for the entire app
      default: '#212121'
    },
    text: {
      primary: '#1e1e1e', // Darker text for better readability
      secondary: '#7387ff', // Use blue for secondary text elements
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: '#1e1e1e', // Dark color for headings
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#1e1e1e', // Dark color for headings
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: '#1e1e1e', // Dark color for headings
    },
    body1: {
      fontSize: '1rem',
      color: '#1e1e1e', // Dark color for body text
    },
    body2: {
      fontSize: '0.875rem',
      color: '#1e1e1e', // Dark color for body text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          backgroundColor: '#7387ff', // Blue for button background
          color: '#fff', // White text for better contrast
          '&:hover': {
            backgroundColor: '#4766c6', // Darker blue on hover
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#7387ff', // Link color
          '&:hover': {
            color: '#4766c6', // Darker link color on hover
          },
        },
      },
    },
  },
});

export default theme;
